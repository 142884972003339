import { configureStore, createReducer } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import appReducer from './slices/appSlice'
import subscriptionReducer from './slices/subscriptionSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

const userPersistedReducer = persistReducer(
  { key: 'user', storage },
  userReducer
)

export const store = configureStore({
  reducer: {
    user: userPersistedReducer,
    app: appReducer,
    subscriptions: subscriptionReducer
  }
})

export const persistor = persistStore(store)
