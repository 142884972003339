import { createBrowserRouter } from 'react-router-dom'
import AuthPages from './screens/auth/authPages'
import AppPages from './screens/mainApp/appPages'

const ROUTES = {
  Auth: {
    screens: {
      // Welcome: {
      //   path: '/welcome',
      //   element: <AuthPages.Welcome />
      // },
      Onboard: {
        path: '/auth',
        element: <AuthPages.Onboard />
      },
      PasswordReset: {
        path: '/reset-password',
        element: <AuthPages.RestPassword />
      }
    }
  },
  MainApp: {
    screens: {
      Chat: { path: '/chat', element: <AppPages.Chat /> },
      Subscribe: { path: '/subscribe', element: <AppPages.Subscribe /> }
    }
  }
}

export default ROUTES

export function extractNestedRoutes (routeObject) {
  if (routeObject.screens && Object.keys(routeObject.screens).length > 0) {
    const nested_routes = []
    Object.keys(routeObject.screens).map(key => {
      const _routeSingle = routeObject.screens[key]
      nested_routes.push({ ..._routeSingle })
    })
    return nested_routes
  }

  return routeObject
}
