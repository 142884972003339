import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setUserSubscriptions: (state, action) => {
      return action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUserSubscriptions } = subscriptionSlice.actions

export default subscriptionSlice.reducer
