import {
  Badge,
  Button,
  Grid,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserSubscriptions } from '../../redux/slices/subscriptionSlice'
import { httpsCallable } from 'firebase/functions'
import { FaArrowRight } from 'react-icons/fa'

export default ({}) => {
  const firebaseState = useSelector(state => state.app.firebase)
  const customerId = useSelector(state => state.user.customerId)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [plans, setPlans] = useState()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isPlanBusy, setIsPlanBusy] = useState()
  const toast = useToast()

  const userSubscriptions = useSelector(state => state.subscriptions)
  const hasSubscription = userSubscriptions?.some(
    x => x.status === 'active' && !x.ended_at
  )

  useEffect(() => {
    if (customerId) {
      getCustomer()
    }
  }, [customerId])

  useEffect(() => {
    getPlans()
  }, [])

  async function getCustomer () {
    console.log('Fetching customer...')
    setIsFetching(true)
    const customer = httpsCallable(firebaseState.functions, 'retrieveCustomer')
    const result = await customer({ customerId })
    // console.log('Customer is:', result.data)
    // console.log('Subscription should be:', result.data.subscriptions)
    dispatch(setUserSubscriptions(result.data.subscriptions))
    setIsFetching(false)
  }

  async function getPlans () {
    console.log('Fetching plans...')
    setIsFetching(true)
    const plans = httpsCallable(firebaseState.functions, 'getPlans')
    const result = await plans()
    // console.log('Plans are:', result.data)
    setPlans(result.data)
    console.log('Plans:', result.data)
    setIsFetching(false)
  }

  async function performCheckout (priceObj) {
    try {
      setIsPlanBusy(true)
      const checkout = httpsCallable(firebaseState.functions, 'createCheckout')
      const result = await checkout({
        customerId,
        redirectUrl: `${window.location.href}`,
        priceId: priceObj.id,
        mode: priceObj.recurring.interval ? 'subscription' : 'payment'
      })
      console.log('Stripe result"', result.data)
      if (result.data.url) {
        window.open(result.data.url, '_self')
      } else {
        setIsPlanBusy(false)
      }
    } catch (ex) {
      setIsPlanBusy(false)
      console.log('Stripe error:', ex)
      toast({
        title: 'Something went wrong',
        description: 'Please try again later'
      })
    }
  }

  function getPlanInterval(planData){
    if(planData.recurring.interval_count == 1){
      return `/${planData.recurring.interval}`
    } else return `/${planData.recurring.interval_count}mo`
  }

  return (
    <VStack w='full' h='full' align={'flex-start'} px='6' py='3'>
      {isFetching && (
        <VStack w='full' h='full' justify={'center'} py='10'>
          <Spinner color='black' />
          <Text>Checking your subscription...</Text>
        </VStack>
      )}
      {!isFetching && plans && (
        <>
          <Text
            textAlign={'center'}
            py='3'
            fontSize={'24px'}
            fontWeight={'semibold'}
            pb='6'
          >
            Please select a plan to start using NotaryGPT
          </Text>
          <Grid
            gridTemplateColumns={!isMobile && 'repeat(3, 1fr)'}
            gridTemplateRows={isMobile && 'repeat(3, max-content)'}
            gridColumnGap={'3'}
            gridRowGap={'3'}
            pb={isMobile && '10'}
          >
            {plans
              ?.sort((a, b) => a.unit_amount - b.unit_amount)
              .map(single => (
                <VStack
                  key={`plan_item_${single.id}`}
                  px='4'
                  py='3'
                  borderRadius={'md'}
                  bg={
                    single.product.name !== 'Basic Plan' ? 'green' : '#e3e3e3'
                  }
                  align={'flex-start'}
                  spacing={'5'}
                  color={
                    single.product.name !== 'Basic Plan' ? 'white' : 'black'
                  }
                >
                  <VStack spacing={'2'} align={'flex-start'}>
                    <Badge
                      fontSize={'16px'}
                      fontWeight={'semibold'}
                      opacity={single.product.name === 'Basic Plan' && '0'}
                    >
                      {single.product.name === 'Gold Plan' ? '40' : '59'}% OFF
                    </Badge>
                    <VStack align={'flex-start'} spacing={'0'}>
                      <Text fontSize={'16px'} fontWeight={'semibold'}>
                        {single.product.name}
                      </Text>
                      <HStack>
                        <Text
                          fontSize={'24px'}
                          fontWeight={'bold'}
                          display={'flex'}
                        >
                          $ {single.unit_amount / 100}{' '}
                        </Text>

                        <Text fontSize={'16px'}>
                          {getPlanInterval(single)}
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                  <VStack w='full' h='full' justify={'space-between'}>
                    <Text fontSize={'14px'} lineHeight={'1.5rem'}>
                      {single.product.description}
                    </Text>
                    <Button
                      size='sm'
                      w='full'
                      rightIcon={<FaArrowRight />}
                      onClick={() => performCheckout(single)}
                      isLoading={isPlanBusy}
                    >
                      Get Now
                    </Button>
                  </VStack>
                </VStack>
              ))}
          </Grid>
        </>
      )}
    </VStack>
  )
}
