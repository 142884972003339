import {
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  Textarea,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BsMagic } from 'react-icons/bs'

export default () => {
  const [chatBusy, setIsChatBusy] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [chatState, setChatState] = useState([])
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const chatContainer = useRef()

  useEffect(() => {
    if (chatState[chatState.length - 1]?.role === 'user') {
      console.log('Will call chat now..')
      callChat()
    }
  }, [chatState])

  useEffect(() => {
    if (!chatContainer.current) return

    const resizeObserver = new ResizeObserver(() => {
      console.log('Size is changed...')
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight
      chatContainer.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    })
    resizeObserver.observe(chatContainer.current)
    return () => resizeObserver.disconnect() // clean up
  }, [chatContainer.current])

  async function callChat () {
    // const chat = httpsCallable(firebaseState.functions, 'chat')
    const functions_url = () => {
      // return 'https://chat-nxq7ky3cnq-uc.a.run.app'
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://192.168.0.133:5001/notarygpt/us-central1/chat'
      } else return 'https://chat-nxq7ky3cnq-uc.a.run.app'
    }
    setIsChatBusy(true)
    fetch(functions_url(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(chatState)
    })
      .then(response => {
        // Check if the response body is a readable stream
        const reader = response.body.getReader()
        const decoder = new TextDecoder() // To decode the incoming stream into text

        // Function to read and process the stream
        const readStream = () => {
          reader.read().then(function processStream ({ done, value }) {
            if (done) {
              console.log('Stream complete')
              return
            }

            // Decode the chunk from the stream
            const decodedChunk = decoder.decode(value, { stream: true })

            // Handle multiple lines in the chunk (for multi-line or multi-chunk responses)
            const lines = decodedChunk.split('\n')
            for (let line of lines) {
              if (line.startsWith('data: ')) {
                // Extract the content after 'data:'
                const content = line.replace('data: ', '').trim()

                // Check if the content is the [END] marker
                if (content === '[END]') {
                  console.log('Stream end marker received. Should close chat.')
                  setIsChatBusy(false)
                  return // Exit the function, no need to continue reading
                }

                if (content) {
                  try {
                    // Parse the content if it's valid JSON
                    const parsedData = JSON.parse(content)

                    // Now, handle the parsed data (e.g., update the state)
                    // console.log('Received data:', parsedData)
                    setChatState(prev => {
                      const shallow = [...prev]
                      const lastElem = shallow[shallow.length - 1]

                      if (lastElem.role === 'system') {
                        shallow[shallow.length - 1] = {
                          ...lastElem,
                          content: lastElem.content + parsedData
                        }
                        return shallow
                      } else {
                        return [
                          ...prev,
                          {
                            role: 'system',
                            content: parsedData
                          }
                        ]
                      }
                    })
                  } catch (error) {
                    setIsChatBusy(false)
                    console.error('Failed to parse JSON for chat:', error)
                  }
                }
              }
            }

            // Continue reading the stream
            readStream()
          })
        }

        readStream() // Start reading the stream
      })
      .catch(error => {
        setIsChatBusy(false)
        console.error('Error:', error) // Handle any errors
      })
  }

  function performChat (value) {
    setChatState(prev => [
      ...prev,
      {
        role: 'user',
        content: value || userInput
      }
    ])
    setUserInput('')
  }
//   function isOkay () {
//     return userId && !isApiBusy && hasSubscription
//   }

  return (
    <Grid
      w='full'
      h='full'
      gridTemplateRows={'1fr auto'}
    >
      <GridItem color={'black'} overflow={'scroll'}>
        <VStack
          ref={chatContainer}
          spacing={'5'}
          h={chatState.length > 0 ? 'max-content' : '100%'}
          justify={chatState.length > 0 ? 'flex-start' : 'flex-end'}
          py='3'
          px='4'
        >
          {chatState?.map((chatData, index) => (
            <ChatItem key={`chat_item_${index}`} {...chatData} />
          ))}
          {chatState.length <= 0 && <DemoQuestions onAsk={performChat} />}
        </VStack>
      </GridItem>
      <GridItem
        py='2'
        pb='5'
        alignContent={'center'}
        px={isMobile ? '5' : '10'}
        bgGradient={'linear(to-t, white, transparent)'}
      >
        <HStack
          bg='white'
          borderRadius={'full'}
          h='max-content'
          p='0'
          minH={'40px'}
          maxH={'120px'}
          pr='1'
          // border={'1px solid green'}
          boxShadow={'0px 0px 10px 0px rgba(0,0,0,0.15)'}
        >
          <Textarea
            borderRadius={'full'}
            border={'0'}
            color='black'
            placeholder={
              chatState.length <= 0
                ? 'Type question and press Enter/Return ↵'
                : 'Ask your next question'
            }
            minH={'40px'}
            resize={'none'}
            maxH={'120px'}
            value={userInput}
            type='submit'
            onChange={e => !chatBusy && setUserInput(e.target.value)}
            onKeyUp={e => {
              if (e.key === 'Enter') performChat()
            }}
            isDisabled={chatBusy}
          />
          {!isMobile && (
            <Button
              size='sm'
              onClick={performChat}
              isDisabled={chatBusy}
              colorScheme='green'
              px='4'
              borderRadius={'full'}
            >
              Ask NotaryGPT
            </Button>
          )}
        </HStack>
      </GridItem>
    </Grid>
  )
}

const ChatItem = ({ role, content }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  return role === 'user' ? (
    <HStack w='full' justify={'flex-end'}>
      <Text
        blackSpace={'pre-wrap'}
        maxW={isMobile ? '95%' : '60%'}
        style={{
          textWrap: 'wrap'
        }}
        bg='#e3e3e3'
        px='4'
        py='3'
        borderRadius={'md'}
      >
        {content}
      </Text>
    </HStack>
  ) : (
    <HStack w='full' justify={'flex-start'}>
      <VStack w='full' align={'flex-start'}>
        <Text px='4' fontSize={'14px'} fontWeight={'bold'}>
          NotaryGPT's Response
        </Text>
        <Text
          blackSpace={'pre-wrap'}
          maxW={isMobile ? '95%' : '60%'}
          style={{
            textWrap: 'wrap'
          }}
          px='4'
          borderRadius={'md'}
          bg='#008a1b'
          py='3'
          color={'white'}
          // bgGradient={'linear(to-t, #009c1f30, #009c1f20)'}
        >
          {content}
        </Text>
      </VStack>
    </HStack>
  )
}

const DemoQuestions = ({ onAsk }) => {
  const questions = [
    'What are the legal requirements for notarizing documents in different states?',
    'How do I properly record a notarization in my notary journal while on the go?',
    'How do I handle multiple signers in a single notarization appointment while traveling to different locations?'
  ]

  return (
    <VStack spacing={'2'} minH={'100$'} px='5'>
      <Text fontSize={'16px'} fontWeight={'500'}>
        Hello fellow Notary! Ask me anything!
      </Text>
      {questions.map((qs, index) => (
        <HStack
          key={`demo_qs_${index}`}
          transition={'all 300ms'}
          bg='#008a1b'
          px='4'
          py='3'
          borderRadius={'lg'}
          color='white'
          cursor={'pointer'}
          _hover={{
            transform: 'scale(1.03)'
          }}
          onClick={() => onAsk(qs)}
          fontWeight={'semibold'}
        >
          <BsMagic size='25px' />
          <Text fontSize={'14px'}>{qs}</Text>
        </HStack>
      ))}
    </VStack>
  )
}
