import {
  Button,
  Input,
  TabPanel,
  TabPanels,
  Tabs,
  useStatStyles,
  useToast,
  VStack
} from '@chakra-ui/react'
import { confirmPasswordReset, sendPasswordResetEmail } from 'firebase/auth'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default () => {
  const toast = useToast()
  const navigate = useNavigate()
  const firebaseState = useSelector(state => state.app.firebase)
  const [searchParams] = useSearchParams()
  const [authStep, setAuthStep] = useState(0)
  const oobCode = searchParams.get('oobCode')
  useEffect(() => {
    if (oobCode) {
      setAuthStep(1)
    }
  }, [searchParams])
  async function performSendResetMail ({ email }) {
    try {
      const result = await sendPasswordResetEmail(
        firebaseState.auth,
        email
        // reset_link
      )
      toast({
        title: 'Email sent',
        description: 'Please check your inbox',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
    } catch (ex) {
      console.log('Password reset error is:', ex)
      toast({
        title: 'Could not send email',
        description: 'Please verify that the email is correct',
        status: 'error',
        isClosable: true,
        duration: 2000
      })
    }
  }

  async function performReset ({ password, rePassword }) {
    try {
      if (password !== rePassword) {
        toast({
          title: 'Passwords dont match',
          description: 'Please re-enter your password again',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
        return
      }
      console.log('OOB code:', oobCode)
      await confirmPasswordReset(firebaseState.auth, oobCode, password)
      toast({
        title: 'Reset successful',
        description: 'Please login again',
        status: 'success',
        isClosable: true,
        duration: 2000
      })
      navigate('/auth?login=true')
    } catch (ex) {
      console.log('Password reset error:', ex)
      toast({
        title: 'Could not reset password',
        description: 'Something went wrong!',
        status: 'error',
        isClosable: true,
        duration: 2000
      })
    }
  }
  return (
    <VStack>
      <Tabs index={authStep} w='full'>
        <TabPanels>
          <TabPanel px='0'>
            <Formik
              initialValues={{
                email: ''
              }}
              onSubmit={async values => {
                await performSendResetMail(values)
              }}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                // <Form style={{ width: '100%' }}>
                <VStack w='full'>
                  <Input
                    bg='#ececec'
                    placeholder='Enter Your Email'
                    border={'0'}
                    color='black'
                    value={values.email}
                    onChange={e => setFieldValue('email', e.target.value)}
                    autoFocus={true}
                  />
                  <Button
                    w='full'
                    size='md'
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    colorScheme='green'
                    type='submit'
                  >
                    Send Password Reset Email
                  </Button>
                </VStack>
              )}
            </Formik>
          </TabPanel>
          <TabPanel>
            <Formik
              initialValues={{
                password: '',
                rePassword: ''
              }}
              onSubmit={async values => {
                await performReset(values)
              }}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                // <Form style={{ width: '100%' }}>
                <VStack w='full'>
                  <Input
                    bg='#ececec'
                    placeholder='Enter New Password'
                    border={'0'}
                    color='black'
                    value={values.password}
                    onChange={e => setFieldValue('password', e.target.value)}
                    autoFocus={true}
                    type='password'
                  />
                  <Input
                    bg='#ececec'
                    placeholder='Re-enter Password'
                    border={'0'}
                    color='black'
                    value={values.rePassword}
                    onChange={e => setFieldValue('rePassword', e.target.value)}
                    autoFocus={true}
                    type='password'
                  />
                  <Button
                    w='full'
                    size='md'
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    colorScheme='green'
                    type='submit'
                  >
                    Reset Password
                  </Button>
                </VStack>
              )}
            </Formik>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}
