import {
  Grid,
  GridItem,
  HStack,
  Image,
  useEditable,
  useMediaQuery
} from '@chakra-ui/react'
import { Outlet, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/logo_horizontal.png'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export default () => {
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const navigate = useNavigate()
  const userSubscriptions = useSelector(state => state.subscriptions)
  const hasSubscription = userSubscriptions?.some(
    x => x.status === 'active' && !x.ended_at
  )
  const userid = useSelector(state => state.user.uid)

  useEffect(() => {
    if (hasSubscription) navigate('/chat')
    else navigate('/subscribe')
  }, [hasSubscription])
  return (
    <Grid gridTemplateRows={'70px auto'} h='full'>
      <GridItem boxShadow={'0px 10px 15px 0px rgba(0,0,0,0.05)'} px='4'>
        <HStack
          justify={isMobile ? 'center' : 'flex-start'}
          w='full'
          align={'center'}
          h='full'
        >
          <Image src={Logo} h='50px' />
        </HStack>
      </GridItem>
      <GridItem>
        <Outlet />
      </GridItem>
    </Grid>
  )
}
