import './App.css'
import {
  Badge,
  Button,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import {
  httpsCallable,
  getFunctions,
  connectFunctionsEmulator
} from 'firebase/functions'
import firebaseConfig from './firebaseConfig'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from './redux/slices/appSlice'
import { setUserSubscriptions } from './redux/slices/subscriptionSlice'
import { FaArrowRight } from 'react-icons/fa'
import { AnimatePresence, motion } from 'framer-motion'
import {
  createBrowserRouter,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes, { extractNestedRoutes } from './routes'
import AppLayout from './screens/mainApp/appLayout'
import AuthLayout from './screens/auth/authLayout'

function AuthRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.Auth)
  return createBrowserRouter(_routes)
}
function MainAppRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.MainApp)
  return createBrowserRouter(_routes)
}

function App () {
  const firebaseState = useSelector(state => state.app.firebase)

  const [isApiBusy, setIsApiBusy] = useState()
  const userId = useSelector(state => state.user.uid)
  const dispatch = useDispatch()

  function setUpFirebase () {
    if (!firebaseState) {
      const app = initializeApp(firebaseConfig)
      const auth = getAuth(app)
      const db = getFirestore(app)
      const functions = getFunctions(app)
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(functions, '192.168.0.133', 5001)
        connectAuthEmulator(auth, 'http://192.168.0.133:9099')
        connectFirestoreEmulator(db, '192.168.0.133', 1990)
      }
      // setFirebaseState({ app, auth, db, functions })
      dispatch(
        setAppState({
          key: 'firebase',
          value: {
            app,
            auth,
            db,
            functions
          }
        })
      )
    }
  }

  const RenderScreens = () => {
    setUpFirebase()

    if (firebaseState) {
      if (!userId) {
        console.log('Rendering auth routes')
        return (
          <Router>
            <Routes>
              <Route path='*' element={<NotFound />} />
              <Route path='/' element={<AuthLayout />}>
                {AuthRoutes().routes.map(_route => (
                  <Route key={`routeKey_${_route.path}`} {..._route} />
                ))}
              </Route>
            </Routes>
          </Router>
        )
      } else {
        console.log('Rendering main routes')
        return (
          <Router>
            <Routes>
              <Route path='*' element={<NotFound />} />
              <Route path='/' element={<AppLayout />}>
                {MainAppRoutes().routes.map(_route => (
                  <Route key={`routeKey_${_route.path}`} {..._route} />
                ))}
              </Route>
            </Routes>
          </Router>
        )
      }
    }
  }

  return <RenderScreens />
}

export default App

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return <></>
}
