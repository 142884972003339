import {
  Grid,
  GridItem,
  HStack,
  Image,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import Logo from '../../assets/images/logo_horizontal.png'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const userId = useSelector(state => state.user.uid)
  useEffect(() => {
    if (location.pathname === '/') navigate('/auth')
  }, [])
  return (
    <Grid
      w='full'
      h='full'
      transition={'all 300ms'}
      gridTemplateColumns={isMobile ? '20px 1fr 20px' : 'repeat(3, 1fr)'}
    >
      <GridItem />
      <GridItem>
        <VStack h='full' w='full' justify={'center'}>
          <Grid
            w='full'
            transition={'all 300ms'}
            bg='white'
            gridTemplateRows={
              location.pathname === '/auth' ? '90px auto' : '70px auto'
            }
            h='max-content'
            boxShadow={'0px 0px 15px 0px rgba(0,121,191,0.15)'}
            borderRadius={'md'}
            px='2'
            py='2'
            pb='0'
            gridRowGap={3}
          >
            <HStack
              justify={'center'}
              bg='#ebebeb'
              borderRadius={'md'}
            >
              <Image src={Logo} h='70%' />
            </HStack>
            <GridItem>
              <Outlet />
            </GridItem>
          </Grid>
        </VStack>
      </GridItem>
      <GridItem />
    </Grid>
  )
}
