import { Button, Text, VStack } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default () => {
  const [index, setIndex] = useState(0)
  const navigate = useNavigate()
  const Abouts = [
    {
      title: 'Your 24/7 AI Assistant',
      description:
        "NotaryGPT is your 24/7 assistant—get quick answers on notary laws, document requirements, and best practices while you're on the move."
    },
    {
      title: 'Streamline Your Notary Business',
      description:
        'Streamline your notary business with NotaryGPT—your go-to resource for everything from client questions to legal guidelines, all in one place.'
    },
    {
      title: 'Instant and Reliable Answers On The Go!',
      description:
        'Stay on top of your mobile notary game with NotaryGPT—instant, reliable answers to help you provide seamless service to your clients.'
    }
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex =>
        prevIndex === Abouts.length - 1 ? 0 : prevIndex + 1
      )
    }, 5000) // Change text every 3 seconds

    return () => clearInterval(interval) // Clear interval on unmount
  }, [Abouts.length])

  function goToAuth(){
    navigate('/auth')
  }

  return (
    <VStack
      justify={'center'}
      transition={'all 300ms'}
      px='3'
      py='3'
      borderRadius={'md'}
    >
      <AnimatePresence mode='wait'>
        {
          <motion.div
            style={{ position: 'relative' }}
            key={index} // Key is important for AnimatePresence
            initial={{ opacity: 0, top: '10px' }} // Starting state (hidden)
            animate={{ opacity: 1, top: '0' }} // Animated state (visible)
            exit={{ opacity: 0, top: '-20px' }} // Exit state (hidden again)
            transition={{ duration: 0.3 }} // Animation duration
          >
            <VStack spacing={'4'} align={'flex-start'} minH='160px'>
              <Text fontSize={'24px'} fontWeight={'semibold'}>
                {Abouts[index].title}
              </Text>
              <Text fontSize={'16px'}>{Abouts[index].description}</Text>
            </VStack>
          </motion.div>
        }
      </AnimatePresence>
      <Button colorScheme='green' w='full' onClick={goToAuth}>Get Started</Button>
    </VStack>
  )
}
