import {
  Button,
  Grid,
  Input,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useToast,
  VStack
} from '@chakra-ui/react'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  updateProfile,
  RecaptchaVerifier
} from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/slices/userSlice'
import { httpsCallable } from 'firebase/functions'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { Form, Formik } from 'formik'
import {
  FaArrowLeft,
  FaFacebook,
  FaGoogle,
  FaPhone,
  FaPhoneAlt
} from 'react-icons/fa'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { HiOutlineMail } from 'react-icons/hi'
import { IoRefreshOutline } from 'react-icons/io5'

export default () => {
  const firebaseState = useSelector(state => state.app.firebase)
  const toast = useToast()
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()
  const userId = useSelector(state => state.user.uid)
  const [authType, setAuthType] = useState('email')

  function goToApp () {
    navigate('/')
  }

  useEffect(() => {
    if (userId) {
      goToApp()
    }
  }, [userId])

  async function performLogin ({ email, password }) {
    try {
      const res = await signInWithEmailAndPassword(
        firebaseState.auth,
        email,
        password
      )
      if (res.user) {
        const profile = await getDoc(
          doc(firebaseState.db, `/profile/${res.user.uid}`)
        )
        dispatch(setUser({ uid: res.user.uid, ...profile.data() }))
      } else {
        toast({
          title: 'Could not log in.',
          description: 'Check your credentials and try again',
          isClosable: true,
          status: 'error'
        })
      }
    } catch (ex) {
      console.log('Login error is:', ex)
      toast({
        title: 'Something went wrong',
        description: `Check your credentials and try again.`,
        isClosable: true,
        status: 'error',
        position: isMobile ? 'top' : 'bottom'
      })
    }
  }

  async function performSignup ({ name, email, password }) {
    try {
      console.log('About to create account')
      const res = await createUserWithEmailAndPassword(
        firebaseState.auth,
        email,
        password
      )
      if (res.user) {
        console.log('User was created')
        try {
          await setupProfile(res.user, email, name)
        } catch (ex) {
          console.log('Error is:', ex)
          toast({
            title: 'Could not create customer account',
            description: 'Something went wrong on our side. Try again later.',
            isClosable: true,
            status: 'error'
          })
        }
      } else {
        toast({
          title: 'Could not create account',
          description: 'Check your credentials and try again',
          isClosable: true,
          status: 'error'
        })
      }
    } catch (ex) {
      toast({
        title: 'Something went wrong',
        description: 'Check your credentials and try again',
        isClosable: true,
        status: 'error'
      })
      console.log(ex)
    }
  }

  async function performSocialAuth (type) {
    try {
      const provider =
        type === 'google'
          ? new GoogleAuthProvider()
          : new FacebookAuthProvider()

      const auth_result = await signInWithPopup(firebaseState.auth, provider)
      const user = auth_result.user

      const profile = await getDoc(
        doc(firebaseState.db, `/profile/${user.uid}`)
      )
      if (profile.exists()) {
        dispatch(setUser({ uid: user.uid, ...profile.data() }))
      } else {
        await setupProfile(user, user.email, user.displayName)
      }
    } catch (ex) {
      console.log('Goole auth error:', ex)
      toast({
        title: 'Something went wrong',
        description: 'Could not log in with Google',
        status: 'error',
        isClosable: true
      })
    }
  }

  async function setupProfile (user, email, name) {
    if (name) {
      await updateProfile(user, {
        displayName: name
      })
    }
    const customer = httpsCallable(firebaseState.functions, 'createCustomer')
    const customer_result = await customer({
      email: email,
      name: name
    })
    await setDoc(doc(firebaseState.db, `profile/${user.uid}`), {
      name: name,
      customerId: customer_result.data.id
    })
    dispatch(
      setUser({
        uid: user.uid,
        email: email,
        customerId: customer_result.data.id
      })
    )
  }

  function switchEmailPhone () {
    setAuthType(prev => (prev === 'email' ? 'phone' : 'email'))
  }

  const EmailUI = () => {
    const [searchParams] = useSearchParams()

    const isDefaultLogin = searchParams.get('login')
    
    return (
      <Tabs variant='unstyled' isFitted w='full' defaultIndex={isDefaultLogin ? 1 : 0}>
        <TabList>
          <Tab fontWeight={'bold'} _selected={{ color: 'green' }}>
            Sign Up
          </Tab>
          <Tab fontWeight={'bold'} _selected={{ color: 'green' }}>
            Log In
          </Tab>
        </TabList>
        <TabIndicator
          mt='-1.5px'
          height='2px'
          bg='green.500'
          borderRadius='1px'
        />
        <TabPanels>
          <TabPanel px='0'>
            <Formik
              initialValues={{
                name: '',
                email: '',
                password: '',
                repassword: ''
              }}
              onSubmit={async values => {
                await performSignup(values)
              }}
              // validate={}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                <Form style={{ width: '100%' }}>
                  <VStack w='full'>
                    <Input
                      bg='#ececec'
                      placeholder='Your Name'
                      border={'0'}
                      color='black'
                      value={values.name}
                      onChange={e => setFieldValue('name', e.target.value)}
                    />
                    <Input
                      bg='#ececec'
                      placeholder='Your Email'
                      border={'0'}
                      color='black'
                      value={values.email}
                      onChange={e => setFieldValue('email', e.target.value)}
                    />
                    <Input
                      bg='#ececec'
                      placeholder='New Password'
                      border={'0'}
                      color='black'
                      type='password'
                      value={values.password}
                      onChange={e => setFieldValue('password', e.target.value)}
                    />
                    <Input
                      bg='#ececec'
                      placeholder='Re-Enter Password'
                      border={'0'}
                      color='black'
                      type='password'
                      value={values.repassword}
                      onChange={e =>
                        setFieldValue('repassword', e.target.value)
                      }
                    />
                    <Button
                      colorScheme='green'
                      w='full'
                      size='md'
                      mt='3'
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    >
                      Create Account
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel px='0'>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              onSubmit={async values => {
                await performLogin(values)
              }}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                // <Form style={{ width: '100%' }}>
                <VStack w='full'>
                  <Input
                    bg='#ececec'
                    placeholder='Your Email'
                    border={'0'}
                    color='black'
                    value={values.email}
                    onChange={e => setFieldValue('email', e.target.value)}
                  />
                  <Input
                    bg='#ececec'
                    placeholder='Your Password'
                    border={'0'}
                    color='black'
                    value={values.password}
                    onChange={e => setFieldValue('password', e.target.value)}
                    type='password'
                  />
                  <Button
                    variant={'unstyled'}
                    p='0'
                    lineHeight={'0'}
                    _hover={{
                      color: 'blue'
                    }}
                    alignSelf={isMobile ? 'center' : 'flex-end'}
                    onClick={() => navigate('/reset-password')}
                  >
                    Forgot Password ?
                  </Button>
                  <Button
                    w='full'
                    size='md'
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    colorScheme='green'
                    type='submit'
                  >
                    Log In
                  </Button>
                </VStack>
              )}
            </Formik>
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }
  const PhoneUI = () => {
    const firebaseState = useSelector(state => state.app.firebase)
    const [phone_auth_result, setPhoneAuthResult] = useState()
    const [authStep, setAuthStep] = useState(0)

    useEffect(() => {
      reloadOTPSession()
    }, [])

    async function performPhoneAuth ({ phone = '' }) {
      try {
        const appVerifier = window.recaptchaVerifier
        console.log('Verifier is:', appVerifier)
        const formatted = phone.startsWith('+1') ? phone : `+1${phone}`
        console.log('Number is:', formatted)
        const auth_result = await signInWithPhoneNumber(
          firebaseState.auth,
          formatted,
          appVerifier
        )
        console.log('Got till here...')
        setPhoneAuthResult(auth_result)
        setAuthStep(1)
        toast({
          title: 'OTP Sent',
          description: 'An OTP was sent to your number',
          status: 'success',
          duration: 3000
        })
      } catch (ex) {
        console.log('Something went wrong:', ex)
        toast({
          title: 'Something went wrong',
          description: 'The phone authentication did not work.',
          status: 'error',
          duration: 3000
        })
      }
    }

    async function confirmPhoneLogin ({ code }) {
      try {
        const result = await phone_auth_result.confirm(code)
        const user = result.user

        const profile = await getDoc(
          doc(firebaseState.db, `/profile/${user.uid}`)
        )
        if (profile.exists()) {
          dispatch(setUser({ uid: user.uid, ...profile.data() }))
        } else {
          await setupProfile(user, user.email, user.displayName)
        }
      } catch (ex) {
        console.log('OTP confirmation failed')
        toast({
          title: 'Wrong OTP',
          description: 'Please check the OTP again',
          status: 'error',
          duration: 3000
        })
      }
    }

    function reloadOTPSession () {
      window.recaptchaVerifier = new RecaptchaVerifier(
        firebaseState.auth,
        'phone-auth-submit',
        {
          size: 'invisible',
          callback: response => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit()
            console.log('Allow...')
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            console.log('Re-Captcha Expired!')
          }
        }
      )
      setAuthStep(0)
    }
    return (
      <Tabs variant='unstyled' isFitted w='full' index={authStep}>
        <TabPanels>
          <TabPanel px='0'>
            <Formik
              initialValues={{
                phone: ''
              }}
              onSubmit={async values => {
                await performPhoneAuth(values)
              }}
              // validate={}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                <Form style={{ width: '100%' }}>
                  <VStack w='full'>
                    <Input
                      autoFocus={true}
                      bg='#ececec'
                      placeholder='Phone Number (US Only)'
                      border={'0'}
                      color='black'
                      value={values.phone}
                      onChange={e => setFieldValue('phone', e.target.value)}
                    />
                    <Button
                      id='phone-auth-submit'
                      colorScheme='green'
                      w='full'
                      size='md'
                      mt='3'
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    >
                      Continue
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel px='0'>
            <Formik
              initialValues={{
                code: ''
              }}
              onSubmit={async values => {
                await confirmPhoneLogin(values)
              }}
            >
              {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                // <Form style={{ width: '100%' }}>
                <VStack w='full'>
                  <ReloadOTPButton onClick={reloadOTPSession} />
                  <Input
                    bg='#ececec'
                    placeholder='Enter OPT'
                    border={'0'}
                    color='black'
                    value={values.code}
                    onChange={e => setFieldValue('code', e.target.value)}
                  />
                  <Button
                    w='full'
                    size='md'
                    mt='3'
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    colorScheme='green'
                    type='submit'
                  >
                    Confirm OTP
                  </Button>
                </VStack>
              )}
            </Formik>
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }

  return (
    <VStack
      spacing='1'
      align='center'
      textAlign='center'
      w='full'
      px='4'
      pb='4'
    >
      <VStack w='full' spacing={'2'}>
        {authType === 'email' ? <EmailUI /> : <PhoneUI />}
        <Button
          w='full'
          leftIcon={
            authType === 'phone' ? <HiOutlineMail size='20' /> : <FaPhoneAlt />
          }
          bg='black'
          color='white'
          _hover={{
            bg: 'gray.300',
            color: 'black'
          }}
          onClick={switchEmailPhone}
        >
          Continue With {authType === 'phone' ? 'Email' : 'Phone'}
        </Button>
        <Button
          w='full'
          leftIcon={<FaGoogle />}
          bg='black'
          color='white'
          _hover={{
            bg: 'gray.300',
            color: 'black'
          }}
          onClick={() => performSocialAuth('google')}
        >
          Continue With Google
        </Button>
        {/* <Button
          w='full'
          leftIcon={<FaFacebook />}
          bg='black'
          color='white'
          _hover={{
            bg: 'gray.300',
            color: 'black'
          }}
        >
          Continue With Facebook
        </Button> */}
      </VStack>
    </VStack>
  )
}

const ReloadOTPButton = ({ onClick }) => {
  const timeLeft = useCountdown(60)

  return (
    <Button
      variant={'ghost'}
      size='sm'
      leftIcon={<IoRefreshOutline />}
      w='full'
      onClick={onClick}
      isDisabled={timeLeft > 0}
    >
      Resend OTP in 0:{timeLeft}
    </Button>
  )
}

export const useCountdown = initialSeconds => {
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)

      // Cleanup interval when the countdown is done or component unmounts
      return () => clearInterval(intervalId)
    }
  }, [seconds])

  return seconds
}
