import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firebase: null
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppState: (state, action) => {
      state[action.payload.key] = action.payload.value
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAppState } = appSlice.actions

export default appSlice.reducer
